export const environment = {
  production: true,
  debugMode: true,
  appVersion: 'Version 3.0.2',
  defaultCountry: 'US',
  blockchainExplorerPortal: 'https://optimistic.etherscan.io/tx',
  userHost: 'https://testv3-api.impactedge.ai/api',
  authHost: 'https://testv3-api.impactedge.ai/api',
  authorizationHost: 'https://testv3-api.impactedge.ai/api',
  organizationHost: 'https://testv3-api.impactedge.ai/api',
  categoryHost: 'https://testv3-api.impactedge.ai/api',
  topicHost: 'https://testv3-api.impactedge.ai/api',
  collectHost: 'https://testv3-api.impactedge.ai/api',
  dataContainerHost: 'https://testv3-api.impactedge.ai/api',
  emissionFactorLibraryHost: 'https://testv3-api.impactedge.ai/api',
  equipmentHost: 'https://testv3-api.impactedge.ai/api',
  facilityHost: 'https://testv3-api.impactedge.ai/api',
  productHost: 'https://testv3-api.impactedge.ai/api',
  formHost: 'https://testv3-api.impactedge.ai/api',
  notificationHost: 'https://testv3-api.impactedge.ai/api',
  partnerHost: 'https://testv3-api.impactedge.ai/api',
  activityHost: 'https://testv3-api.impactedge.ai/api',
  noteHost: 'https://testv3-api.impactedge.ai/api',
  storageHost: 'https://testv3-api.impactedge.ai/api',
  blockchainHost: 'https://testv3-api.impactedge.ai/api',
  materialitySurvey: 'https://testv3-api.impactedge.ai/api',
  fileMaxSizeMB: 20,
};
