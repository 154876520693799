import { environment } from '@ieEnv/environment';

type LogLevel = 'log' | 'warn' | 'error';

type CreateLogger = (...args: unknown[]) => void;

const createLogger =
  (type: LogLevel) =>
  (...args: unknown[]): void => {
    if (environment.debugMode) {
      console[type](args);
    }
  };

const logger: Record<LogLevel, CreateLogger> = {
  log: createLogger('log'),
  warn: createLogger('warn'),
  error: createLogger('error'),
} as const;

export default logger;
